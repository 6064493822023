// Importing necessary modules and components from Angular and third-party libraries
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectorRef,
  LOCALE_ID,
  Inject,
} from '@angular/core';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { TrackingService } from 'app/core/services/tracking.service';
import { ApiService } from '../../e-services/reservations/api.service';
import { decode } from 'punycode';
import { UserChoice } from '../userChoice';
import {
  BsDatepickerConfig,
  BsDatepickerViewMode,
} from 'ngx-bootstrap/datepicker';
import { ApiHandlerService } from 'app/core/services/api-handler.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import localeAr from '@angular/common/locales/ar';
import localeEn from '@angular/common/locales/en';
import { DatePipe, registerLocaleData } from '@angular/common';
import { CalendarService } from 'app/home/calendar.service';
import swal from 'sweetalert2';
@Component({
  selector: 'app-doctor-home-visit',
  templateUrl: './doctor-home-visit.component.html',
  styleUrls: ['./doctor-home-visit.component.scss'],
  providers: [
    localStorage.getItem('lang') == 'ar'
      ? { provide: LOCALE_ID, useValue: 'ar' }
      : { provide: LOCALE_ID, useValue: 'en' },
  ],
})
export class DoctorHomeVisitComponent implements OnInit {
  // Declare class properties
  lang: string;
  showSpiner: boolean;
  tdate = new Date();
  today: NgbDateStruct;
  isError = 0;
  specialityList: any = [];
  doctorList: any = [];

  // ViewChild reference for the wizard component
  @ViewChild(WizardComponent)
  public wizard: WizardComponent;
  latitude: any;
  longitude: any;
  isLoading = false;
  form: FormGroup;
  mobilePattern = /^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
  pNamePattern =
    /^[a-zA-Z\u0621-\u064A ]{3,}\s[a-zA-Z\u0621-\u064A ]{3,}(\s[a-zA-Z\u0621-\u064A ]{3,})*$/;
  now = new Date();
  currentDate = {
    year: this.now.getFullYear(),
    month: this.now.getMonth() + 1,
    day: this.now.getDate(),
  };
  queryParams: any;
  user: any;
  addressFormat: any;
  addressLink: any;
  zoom = 17;
  sidebarClose: Boolean = false;
  appointmentBooked: Boolean = false;
  dir: string;
  homeDoctorVisit: boolean = false;
  reservationType: Number = 0;
  appointmentType: Number = 1;
  minDate: Date;
  maxDate: Date;
  bsValue: Date = new Date();
  minMode: BsDatepickerViewMode = 'day';
  bsConfig: Partial<BsDatepickerConfig>;
  doctorDetails: any;
  availableAppointments: any = [];
  currentDateStr: any;
  appointmentData: any = {};
  model: any = {
    selectedSpecialityType: '',
    selectedTime: null,
    selectedDoctor: {},
    slotSerialNumber: '',
  };

  doctorSearchQuery = '';
  specialitySearchQuery = '';
  specialitySearchName = 'API_SPEC_NAME_EN';
  doctorSearchName = 'API_DOCTOR_NAME_EN';

  // Constructor to initialize the component and its dependencies
  constructor(
    private data: ApiService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private _trackingService: TrackingService,
    private apiHandler: ApiHandlerService,
    private cdRef: ChangeDetectorRef,
    private calendarService: CalendarService,
    private datePipe: DatePipe,
    @Inject(LOCALE_ID) private locale: string
  ) {
    // Subscribe to query parameters to check for homeDoctorVisit flag
    this.route.queryParams.subscribe((params) => {
      if (params.homeDoctorVist) {
        this.homeDoctorVisit = true;
      }
    });
    // Set language and direction from localStorage
    this.lang = localStorage.getItem('lang');
    this.dir = this.lang == 'en' ? 'ltr' : 'rtl';

    // Initialize form controls and validators
    this.form = new FormGroup(
      {
        fullName: new FormControl(null, [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('([A-Za-z\u0600-\u06FF]+\\s?)+'),
        ]),
        phoneNumber: new FormControl(null, [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(this.mobilePattern),
        ]),
        city: new FormControl(null, [
          Validators.maxLength(100),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        ]),
        formattedAddress: new FormControl(null, []),
        insurance: new FormControl('0'),
      },
      { updateOn: 'blur' }
    );

    // Set locale based on language for date formatting
    if (this.lang == 'ar') {
      registerLocaleData(localeAr);
      this.locale = 'ar';
    }
    if (this.lang == 'en') {
      registerLocaleData(localeEn);
      this.locale = 'en';
    }
  }
  ngOnInit() {
    // Set names for search based on language preference.
    this.specialitySearchName =
      this.lang == 'ar' ? 'API_SPEC_NAME_AR' : 'API_SPEC_NAME_EN';
    this.doctorSearchName =
      this.lang == 'ar' ? 'API_DOCTOR_NAME_AR' : 'API_DOCTOR_NAME_EN';

    // Set the current date in a specified format.
    this.currentDateStr = moment().format('YYYY-MM-DD').toString();
    const currentDate = new Date();

    // Configure Bootstrap Datepicker options.
    this.bsConfig = Object.assign(
      {},
      {
        keepDatepickerOpened: true,
        minDate: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        ),
        maxDate: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          31
        ),
      }
    );

    // Retrieve additional data by calling getListQuery method.
    this.getListQuery();

    // Adjust UI elements based on language direction.
    if (this.lang == 'en') {
      $('aw-wizard').attr('dir', 'ltr');
      $('ng-select').attr('dir', 'ltr');
      $('.ng-option-label').addClass('text-left');
    } else {
      $('aw-wizard').removeAttr('dir');
      $('ng-select').removeAttr('dir');
      $('.ng-option-label').removeClass('dir');
    }

    // Fetch recent doctor appointment data by calling getRecentDrAppointmentQuery method.
    this.getRecentDrAppointmentQuery();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  get fullName() {
    return this.form.get('fullName');
  }
  get phoneNumber() {
    return this.form.get('phoneNumber');
  }
  get city() {
    return this.form.get('city');
  }
  get formattedAddress() {
    return this.form.get('formattedAddress');
  }
  get insurance() {
    return this.form.get('insurance');
  }

  // Method to fetch a list of active specializations using an API call.
  getListQuery() {
    this.apiHandler.callBookGetQuery('active-specializations', {}).subscribe(
      (data: any) => {
        if (data && data.data && data.data.length > 0) {
          this.specialityList = data.data;
        }
      },
      (err) => {
        console.log('error====>', err);
      }
    );
  }

  // Method triggered when entering the date and time section to update the available appointments.
  dateTimeSectionEnter() {
    if (this.model.selectedTime) {
      this.bsValue = new Date(this.model.selectedTime);
      this.currentDateStr = moment(this.model.selectedTime)
        .format('YYYY-MM-DD')
        .toString();
    } else {
      this.currentDateStr = moment().format('YYYY-MM-DD').toString();
    }
    // Fetch available appointments based on the selected date.
    this.getAvailableAppointments();
  }

  // Method to fetch recent doctor appointments based on selected specialization.
  getRecentDrAppointmentQuery() {
    let params = {};
    if (this.model.selectedSpecialityType) {
      params['specialization'] = this.model.selectedSpecialityType;
    }
    this.doctorList = [];
    this.apiHandler.callAppointmentsGetQuery('recent', params).subscribe(
      (data: any) => {
        if (
          data &&
          data.status == 'success' &&
          data.data &&
          data.data.length > 0
        ) {
          this.doctorList = data.data;
        }
        if (data && data.status == 'error') {
          this.doctorList = [];
        }
      },
      (err) => {
        console.log('error====>', err);
      }
    );
  }

  // Method to fetch available appointments for a specific date and doctor.
  getAvailableAppointments() {
    let params = {
      SpecificDate: this.currentDateStr,
    };
    this.availableAppointments = [];
    if (this.model.selectedDoctor && this.model.selectedDoctor.DOC_CODE) {
      params['ClinCode'] = this.model.selectedDoctor.DOC_CODE;
      params['specialization'] = this.model.selectedSpecialityType;
    }

    this.apiHandler.callAppointmentsGetQuery('specific', params).subscribe(
      (data: any) => {
        if (
          data &&
          data.status == 'success' &&
          data.data &&
          data.data.length > 0
        ) {
          // this.availableAppointments = data.data;
          // Filter available appointments for the selected date.
          this.availableAppointments = data.data.filter((el) => {
            if (el.resTime) {
              el.resTime = el.resTime.replace('Z', '');
            }
            return (
              new Date(this.currentDateStr).getDate() ==
              new Date(el.resTime).getDate() && (new Date().getTime() < new Date(el.resTime).getTime())
            );
          });
        }
      },
      (err) => {
        console.log('error====>', err);
      }
    );
  }

  callToselectDr() {
    if (this.reservationType == 0) {
      this.reservationType = 1;
    } else {
      this.reservationType = 0;
    }
  }

  location(x) {
    this.latitude = x.coords.lat;
    this.longitude = x.coords.lng;
  }

  // Method triggered when the address is changed, updating latitude, longitude, and address details.
  public handleAddressChange(address: Address) {
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();
    this.addressFormat = address.formatted_address;
    this.addressLink =
      `https://www.google.com/maps/place/${this.latitude},${this.longitude}`.replace(
        /\s/g,
        '+'
      );
  }

  // Method to set the current location using the browser's geolocation API.
  // public setCurrentLocation() {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       this.latitude = position.coords.latitude;
  //       this.longitude = position.coords.longitude;
  //       this.addressLink =
  //         `https://www.google.com/maps/place/${this.latitude},${this.longitude}`.toLowerCase();

  //       this.addressFormat = '';
  //       // this.formattedAddress.reset();
  //     });
  //   }
  // }
  public setCurrentLocation(callback?: () => void) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.addressLink =
          `https://www.google.com/maps/place/${this.latitude},${this.longitude}`;
        
        // Call the callback function if provided
        if (callback) {
          callback();
        }
      });
    } else {
      console.error('Geolocation is not available in this browser.');
      if (callback) {
        callback(); // Optionally, call the callback even if geolocation fails
      }
    }
}

  // This function is triggered when the date is changed in the UI.
  dateChange(value: Date): void {
    this.currentDateStr = moment(value).format('YYYY-MM-DD').toString();
    this.getAvailableAppointments();
  }

  // Function to clear search queries.
  clearSearch(item) {
    if (item === 'speciality') {
      this.specialitySearchQuery = '';
    } else if (item === 'doctor') {
      this.doctorSearchQuery = '';
    }
  }

  // This function is prepare data for the appointment booking request.
  newAppBook() {
    if (this.form.invalid || !this.model.slotSerialNumber) {
      return false;
    }

    let data1 = {
      app_serial: this.model.slotSerialNumber,
      appointment_type: this.appointmentType == 2 ? 2 : 1,
      client_name: this.fullName.value,
      client_mobile: this.phoneNumber.value,
      client_city: this.lang == 'ar' ? 'جدة' : 'Jeddah', // this.city.value
      service_cat_code_id: 3,
      client_mrn: '',
      formatted_address: this.addressFormat ? this.addressFormat : '',
      url: this.addressLink ? this.addressLink : '',
      insurance: this.insurance.value,
      geometry: {
        location: {
          lat: this.latitude ? this.latitude : '',
          lng: this.longitude ? this.longitude : '',
        },
      },
      order_items: [
        {
          service_code: this.appointmentType == 2 ? 468 : 536,
          // service_code: this.appointmentType == 2 ? 'H-1000' : 'H-1001', // Updated values
          client_message: '',
        },
      ],
    };
    this.apiHandler.callAppointmentsBook('doctor-appointment', data1).subscribe(
      (data: any) => {
        if (data && data.error) {
          console.log('error====>', data.error);
          let errorMsg = this.lang == 'en' ? 'Something went wrong, please try again later' : 'حدث خطأ, يرجى المحاولة فى وقت لاحق'
          swal({
            title: this.lang == 'en' ? 'Error' : 'خطأ',
            text: data.error.length > 0 ? data.error[0] : errorMsg,
            confirmButtonText: this.lang == 'en' ? 'Done' : 'تم',
            type: 'warning'
          })
        }
        if (data && data.success) {
          this.appointmentData = data;
          this.appointmentBooked = true;
        }
      },
      (err) => {
        console.log('error====>', err);
      }
    );
  }

  goToHome() {
    this.router.navigate(['/e-services']);
  }

  // Reset the form related to appointments.
  newAppointment() {
    window.location.reload();
    this.resetFormAppointment();
    this.appointmentBooked = false;
  }

  // This function reset the properties & set the current date.
  resetFormAppointment() {
    this.model = {
      selectedSpecialityType: '',
      selectedTime: null,
      slotSerialNumber: '',
      selectedDoctor: {},
    };
    this.addressFormat = '';
    this.addressLink = '';
    this.latitude = '';
    this.longitude = '';
    this.form.reset();
    let currentDate = new Date();
    this.bsConfig = Object.assign(
      {},
      {
        minDate: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        ),
        maxDate: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          31
        ),
        showWeekNumbers: false,
      }
    );
    this.bsValue = new Date();
    this.appointmentType = 1;
  }

  // Custom validator function to check if a form control value contains only whitespace.
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  // Function to handle the selection of a speciality from a list.
  callSpecialty(event, specialityList, index) {
    if (
      this.model.selectedSpecialityType &&
      specialityList[index].CODE != this.model.selectedSpecialityType
    ) {
      this.model.selectedDoctor = {};
      this.model.selectedTime = '';
      this.model.slotSerialNumber = '';
    }
    this.getRecentDrAppointmentQuery();
  }

  // Function to handle the selection of a doctor.
  doctorSelected(doctor) {
    if (this.model.selectedDoctor && this.model.selectedDoctor != doctor) {
      this.doctorList.forEach((el, drIndex) => {
        if (el != doctor && el.appointments && el.appointments.length > 0) {
          el.appointments.forEach((element, i) => {
            let tempArr = document.getElementById(
              `appointment_${drIndex}_${i}`
            );
            tempArr.classList.remove('active');
          });
        }
      });
    }
    this.model.slotSerialNumber = '';
    this.model.selectedTime = '';
  }

  // Function to handle the selection of an appointment.
  appointmentSelected(event, session, drInd, index) {
    if (this.doctorList.length > 0) {
      this.doctorList.forEach((el, drIndex) => {
        if (el && el.appointments && el.appointments.length > 0) {
          el.appointments.forEach((element, i) => {
            let tempArr = document.getElementById(
              `appointment_${drIndex}_${i}`
            );
            if (index == i && drInd == drIndex) {
              this.model.selectedDoctor = el;

              if (tempArr.classList.contains('active')) {
                tempArr.classList.remove('active');
                setTimeout(() => {
                  this.model.slotSerialNumber = '';
                  this.model.selectedTime = '';
                }, 500);
              } else {
                tempArr.classList.add('active');
                setTimeout(() => {
                  this.model.slotSerialNumber = session.appSerial;
                  this.model.selectedTime = session.resTime;
                }, 500);
              }
            } else {
              tempArr.classList.remove('active');
            }
          });
        }
      });
    }
  }

  // Function to handle the selection of a time slot and set the serial number and time in modal.
  selectTimeSlot(event, item) {
    if (item && item.appSerial) {
      this.model.slotSerialNumber = item.appSerial;
      this.model.selectedTime = item.resTime;
    }
  }

  openExperienceDetailsPopup(content, item) {
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
    });
    this.doctorDetails = item;
  }

  closePopup() {
    this.modalService.dismissAll();
  }

  cancelService() {
    this.router.navigate(['/e-services']);
  }
  saveAppointmentToCalendar(appointmentData) {
    const startDate = new Date(appointmentData.date_and_time);
    const endDate = new Date(appointmentData.date_and_time);
    const title =
      this.lang == 'ar'
        ? `موعد - عناية متجددة للرعاية الصحية`
        : `Appointment - Enaya Health Care`;
    const location = '';
    const description =
      this.lang == 'ar'
        ? `الخدمة: ${appointmentData.service_name_ar}`
        : `Service Name: ${appointmentData.service_name_en}`;
    this.calendarService.addEventToCalendar(
      title,
      startDate,
      endDate,
      description,
      location
    );
  }

  // Function to share appointment details via WhatsApp
  shareWhatsapp(appointmentData) {
    const date = new Date(appointmentData.date_and_time);

    // Formatting the date using Angular's datePipe
    const formattedDate = this.datePipe.transform(date, 'yyyy-MM-dd, , h:mm a');

    // Constructing the WhatsApp message based on language preference
    const message =
      this.lang == 'en'
        ? `*Enaya For Health Care*\nYour appointment has been booked successfully\nOrder Number: ${appointmentData.order_number}\nPatient Name: ${appointmentData.client_name}\nService Name: ${appointmentData.service_name_en}\nService Type: ${appointmentData.category_en}\nAppointment Date & Time: ${formattedDate}`
        : `*عناية متجددة للرعاية الصحية*\nتم حجز موعدكم بنجاح\nرقم الطلب: ${appointmentData.order_number}\nاسم المريض: ${appointmentData.client_name}\nاسم الخدمة: ${appointmentData.service_name_ar}\nنوع الخدمة: ${appointmentData.category_ar}\nتاريخ الموعد: ${formattedDate}`;

    const url = `whatsapp://send?text=${encodeURIComponent(message)}`;

    // Redirecting to the WhatsApp URL
    window.location.href = url;
  }
}
